import * as React from 'react'
import Layout from '../components/layout'
import {Titulo} from '../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'

export default function admision () {
    const data = [
        {id: '1', title: 'Registro Anticipado', date:'Desde el 25 de Julio a las 9 horas'},
        {id: '2', title: 'Postulaciones', date:'desde el 02 de agosto a las 9 horas hasta el 30 de agosto a las 14 horas'},
        {id: '3', title: 'Resultados', date:'desde el 17 de octubre a las 9 horas hasta el 23 de octubre a las 14 horas'},
        {id: '4', title: 'Resultado de listas de espera', date:'desde el 04 de noviembre a las 9 horas hasta el 05 de noviembre a las 14 horas'},
        {id: '5', title: 'Postulacion complementaria', date:'desde el 15 de noviembre a las 9 horas hasta el 22 de noviembre a las 14 horas'},
        {id: '6', title: 'Resultados', date:'desde el 04 de diciembre a las 9 horas'},
        {id: '7', title: 'Periodo de matricula', date:'desde el 05 de diciembre a las 9 horas hasta el 13 de diciembre a las 14 horas'},
        {id: '8', title: 'Regularizacion', date:'desde el 18 de diciembre de acuerdo con la zona geografica'}
    ]

    return (
        <Layout>
            <Titulo title="Proceso de admisión" />
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>Postula a través de
                        <a href="https://www.sistemadeadmisionescolar.cl/" target="_blank" rel="noreferrer">
                            <StaticImage 
                                src="../images/enlaces/sae_logo.svg" 
                                alt="" 
                                height={60}
                            />
                        </a>
                        </h3>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                    <Col md={12}><h3>Fechas importantes</h3></Col>
                    {data && data.map( e => (
                        <Col md={4} key={e.id}>
                            <Card className="mt-3 bg-dark text-center">
                                <Card.Body>
                                    <h5>{e.title}</h5>
                                    <h6>{e.date}</h6>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col md={12} className="mb-5"></Col>
                </Row>
                   
            </Container>

        </Layout>
    )
}